import { connect } from 'react-redux'
import { RootState } from '../../../store'
import { updateSelectedLocation } from '../../../store/search/actions'
import { openSelectedUrl } from '../../../store/details/slice'

import { updateFirstLoad } from '../../../store/search/slice'
import ListingsView from './ListingsView'

const mapStateToProps = (state: RootState) => {
  return {
    currentSelection: state.details.currentSelection,
    recents: state.details.recents,
    showRecentsPanel: state.details.showRecentsPanel,
    shouldShowLocationTab:
      state.search.isFirstLoad &&
      state.search.selectedLocation !== 1 &&
      state.details.currentSelection[0]?.id === state.search.selectedLocation,
  }
}

const ConnectedListingsView = connect(mapStateToProps, {
  updateFirstLoad,
  updateSelectedLocation,
  openSelectedUrl,
})(ListingsView)

export default ConnectedListingsView

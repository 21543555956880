import { ICommunity, IMHBOListing, IMobileHome } from 'mhbo-js'
import { FunctionComponent } from 'react'
import { Element } from 'react-scroll'
import PaginationComponent from '../../PaginationComponent/PaginationComponent'
import AdComponent from '../AdComponent'
import ListingPreviewCell from '../ListingPreviewCell/index'
import { Launch } from '@mui/icons-material'
import { useAppSelector } from '../../../store/hooks'

interface IProps {
  currentSelection: Array<IMobileHome | ICommunity>
  updateFirstLoad: () => void
  updateSelectedLocation: (selectedLocation: number) => void
  recents: Array<IMobileHome | ICommunity>
  showRecentsPanel: boolean
  openSelectedUrl: (id: number) => void
  shouldShowLocationTab: boolean
}

const ListingsView: FunctionComponent<IProps> = ({
  currentSelection,
  updateFirstLoad,
  updateSelectedLocation,
  showRecentsPanel,
  recents,
  openSelectedUrl,
  shouldShowLocationTab,
}: IProps) => {
  const activePage = useAppSelector((state) => state.search.currentPageNumber)

  // const [ads, setAds] = useState<number[]>([])

  /* useEffect(() => {
    let adIndex = 2
    const newads: number[] = []
    while (adIndex < currentSelection.length) {
      newads.push(adIndex)
      adIndex += Math.random() < 0.5 ? 3 : 4
    }
    if (newads.length !== ads.length) {
      setAds(newads)
    }
  }, [currentSelection]) */
  if (shouldShowLocationTab) {
    const selectedListing = currentSelection[0] as any
    if (selectedListing) {
      return (
        <>
          <Element name={`listing${selectedListing.id}`}>
            <ListingPreviewCell
              listing={selectedListing}
              isLazy={false}
              onClick={() => openSelectedUrl(selectedListing.id)}
            />
            <AdComponent id="selectedentity" />

            <div
              dangerouslySetInnerHTML={{
                __html:
                  selectedListing.description ||
                  selectedListing.propertyDescription,
              }}
            />
            <p
              className="listingCell__community_link"
              onClick={() => openSelectedUrl(selectedListing.id)}
            >
              See Details
              <Launch />
            </p>
          </Element>
        </>
      )
    }
  }
  const showSelection = showRecentsPanel ? recents : currentSelection
  return (
    <div>
      {showSelection.map((listing: IMHBOListing, index: number) => {
        return (
          <span key={listing.id}>
            {[
              2,
              currentSelection.length / 2,
              currentSelection.length - 3,
            ].includes(index) ? (
              <AdComponent
                key={`ad-${activePage}-${index}-${listing.id}`}
                id={`ad-${activePage}-${index}-${listing.id}`}
              />
            ) : null}
            <Element name={`listing${listing.id}`}>
              <ListingPreviewCell
                listing={listing}
                isLazy={index > 3}
                onClick={() => openSelectedUrl(listing.id)}
                onMouseOver={() => {
                  updateFirstLoad()
                  updateSelectedLocation(listing.id)
                }}
                onMouseOut={() => updateSelectedLocation(-1)}
              />
            </Element>
          </span>
        )
      })}
      {showRecentsPanel ? null : <PaginationComponent />}
    </div>
  )
}

export default ListingsView

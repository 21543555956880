import { useEffect } from 'react'
import { IPrimisVideoAdProps } from './interfaces'

declare global {
  interface Window {
    googletag: any
  }
}

const PrimisVideoAd = ({ id }: IPrimisVideoAdProps) => {
  useEffect(() => {
    if (window.googletag) {
      window.googletag.cmd.push(function () {
        // window.googletag.destroySlots()
        console.log(` Define slot: div-gpt-ad-1726688341035-0-${id}`)
        window.googletag
          ?.defineSlot(
            '/1012891/mapsinventory/List_View_Ads',
            [
              [300, 250],
              [320, 480],
            ],
            `div-gpt-ad-1726688341035-0-${id}`
          )
          .addService(window.googletag.pubads())
        window.googletag?.pubads().collapseEmptyDivs()
        window.googletag?.enableServices()
        window.googletag?.display(`div-gpt-ad-1726688341035-0-${id}`)
      })
    }
    return () => {
      if (window.googletag) {
        window.googletag.cmd.push(function () {
          window.googletag.destroySlots([`div-gpt-ad-1726688341035-0-${id}`])
          console.log('destroyed')
        })
      }
    }
  }, [])

  return (
    <div
      id={`div-gpt-ad-1726688341035-0-${id}`}
      className={'gptads__cover'}
      style={{
        minWidth: '300px',
        minHeight: '100px',
      }}
    />
  )
}

export default PrimisVideoAd
